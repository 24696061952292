div:has(dl#iiif-manifest-metadata) {
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

div[role="radiogroup"] {
  overflow-x: hidden;
}

form:has(label[for="information-toggle"]) {
  display: none;
}

#iiif-logo {
  display: none;
}

.webvtt-cue, .webvtt-cue * {
  display: block;
  width: 100%;
}

.webvtt-cue [title="dreyfus"] {
  text-align: left;
  font-style: italic;
}

.webvtt-cue [title="klein"] {
  text-align: right;
  font-weight: bold;
}